* {
  box-sizing: border-box;
}

#pc {
  background-color: #ffffff;
  color: #d93125;
}

body {
  background-color: #f2f2f2;
  font-family: "Open Sans", sans-serif;
}

.newBtn {
  border: none;
  font-size: 1rem;
  font-weight: 900;
}

.pstBtn {
  border: none;
  font-weight: 900;
  font-size: 1rem;
}

.n-p-btns {
  margin: 10px;
}

.loadBtn {
  border: none;
  background-color: #000000;
  width: 95vw;
  /* max-width: 400px; */
  align-content: center;
  height: 40px;
  border-radius: 15px;
  font-size: 1rem;
  font-weight: 900;
  margin-top: 10px;
  color: #f2f2f2;
}

.loadBtnPre {
  border: none;
  background-color: #000000;
  width: 95vw;
  /* max-width: 400px; */
  align-content: center;
  height: 40px;
  border-radius: 15px;
  font-size: 1rem;
  font-weight: 900;
  margin: 10px auto;
  color: #f2f2f2;
}

.btn-container {
  align-items: center;
  display: flex;
  flex-direction: column;

  margin-left: 10px;
  background-color: #f2f2f2;
}

.bot-header-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #f95c38;
  height: 110px;
  justify-content: center;
  font-size: 1.5rem;
  font-weight: 900;
  bottom: 0;
}

.active {
  background-color: rgb(254, 156, 149);
  color: #ffffff;
}

.new-btn {
  text-decoration: none;
  margin: 0 5px;
  font-size: 1.3rem;
  padding: 4px 8px;
  border-radius: 10px;
  color: #000000;
  font-weight: 800;
}

.past-btn {
  text-decoration: none;
  font-size: 1.3rem;
  border-radius: 10px;
  color: #000000;
  padding: 4px 8px;
  font-weight: 800;
}

.header {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 50px;
  justify-content: center;
  background-color: #ffffff;
  font-size: 1.3rem;
}
