.storyContainer {
  background-color: #fafafa;
  border-radius: 10px;
  margin: 15px;
  padding: 1px 15px 10px 15px;
}

.storyLink {
  color: #000000;
  text-decoration: none;
}

.details {
  display: flex;
  font-size: 0.8rem;
}

.clock {
  margin: 0 8px 0 0;
  font-size: 1rem;
}

.discription {
  font-size: 0.8rem;
}

.title {
  font-weight: 700;
}
